.nav {
    width: 90vw;
    height: 10vh;
    background-color: #DBFF00;
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    justify-content: space-between;
    position: fixed;
    left: 5vw;
    top: 5vh;
    z-index: 110;
    border: 2px solid black;
}

.logo h1 {
    font-family: Metropolis Bold;
    color: black;
    font-size: 2.5vw;
}

.links a {
    font-family: Baron Neue;
    font-size: 1.5vw;
    margin: 0 20px;
    cursor: default;
}

.projects {
    /* display: flex; */
    color: black;
    position: relative;
}

.projects::after {
    content: '';
    position: absolute;
    background-color: black;
    height: 3px;
    width: 0;
    left: 0;
    bottom: -1.5vh;
    transition: 0.3s;
}

.projects:hover::after {
    width: 100%;
}

.contact {
    color: white;
    background-color: black;
    padding: 20px 20px;
    padding-top: 13px;
    border-radius: 30px;
    transition: 0.3s;
    border: 2px solid black;
    cursor: default;
}

.contact:hover {
    color: black;
    background-color: #DBFF00;
    border: 2px solid black;
}

@media only screen and (max-width: 800px) {
    .logo h1 {
      font-size: 5vw;
    }

    .links {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .logo h1 {
        font-size: 7vw;
    }

    .nav {
        justify-content: center;
    }
}