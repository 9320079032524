@font-face {
  font-family: Baron Neue;
  src: url(/public/fonts/Baron\ Neue.otf);
}

@font-face {
  font-family: Metropolis;
  src: url(/public/fonts/Metropolis-Regular.otf);
}

@font-face {
  font-family: Metropolis Semi;
  src: url(/public/fonts/Metropolis-SemiBold.otf);
}

@font-face {
  font-family: Metropolis Bold;
  src: url(/public/fonts/Metropolis-Bold.otf);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* cursor: none; */
}

h1 {
  font-family: Baron Neue;
  color: white;
}

h2 {
  font-family: Metropolis;
  color: white;
}

p {
  font-family: Metropolis;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  overflow-x: hidden;
  max-width: 100vw;
}
