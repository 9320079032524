.portfolio {
    height: 100vh;
}

.card {
    height: 100%;
    width: 90vw;
    display: flex !important;
    margin-left: 5vw;
}

.card-left {
    height: 80vh;
    width: 30vw;
    background-color: #DBFF00;
    padding-top: 5vh;
    padding-left: 2vw;
    border-radius: 50px;
    transition: 0.3s;
}

#light .card-left {
    border: 2px solid black;
}

#light .card-left {
    border-radius: 0;
}

.card-left h1 {
    color: black;
    font-size: 2.5vw;
    font-weight: 300;
    position: relative;
    margin-bottom: 5vh;
}

.card-left h1::after {
    position: absolute;
    content: '';
    height: 4px;
    bottom: -1.5vh;
    background-color: black;
	left: 0;
    width: 40%;
}

.card-left p {
    font-size: 1.3vw;
    font-family: Metropolis;
    margin-bottom: 1.2vh;
}

.card-left p span {
    font-family: Metropolis Semi;
}

.card-left a {
    text-decoration: underline;
    color: black;
    font-family: Metropolis;
    font-size: 1.2vw;
}

.card-details {
    margin-bottom: 15vh;
}

.card-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60vw;
}

#light .card-right {
    border: 2px solid black;
    border-left: none;
}

.card-right img {
    max-width: 50vw;
    /* margin-left: 5vw; */
}

.prev-arrow {
    position: absolute;
    top: 60vh !important;
    left: 15vh;
    z-index: 1;
    cursor: pointer;
}

.next-arrow {
    position: absolute;
    top: 60vh !important;
    left: 25vh;
    z-index: 1;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .portfolio {
        height: 70vh;
    }

    .card {
        flex-direction: column;
        align-items: center;
        margin-left: 0vw;
        /* max-width: 90vw; */
    }

    .card-left {
        height: 35vh;
        width: 80vw;
        background-color: #DBFF00;
        padding-top: 5vh;
        padding-left: 2vw;
        border-radius: 0px;
        transition: 0.3s;
        padding-left: 5vw;
    }

    .card-left h1 {
        color: black;
        font-size: 8vw;
        font-weight: 300;
        position: relative;
        margin-bottom: 5vh;
    }

    .card-left p {
        font-size: 4vw;
        font-family: Metropolis;
        margin-bottom: 1.2vh;
    }

    .card-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 30vh;
    }

    .card-right img {
        max-width: 70vw;
        /* margin-left: 5vw; */
    }

    .prev-arrow {
        top: 27vh !important;
        left: 12vw;
    }

    .prev-arrow img {
        max-width: 10vw;
    }
    
    .next-arrow {
        top: 27vh !important;
        left: 29vw;
    }

    .next-arrow img {
        max-width: 10vw;
    }
 }