.cursor {
    background-color: white;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    z-index: 100;
}

#light {
    background-color: white;
}

#dark {
    background-color: black;
}

.switch {
    position: fixed;
    /* border: 2px solid white; */
    /* border-radius: 30px; */
    height: 100px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 17vh;
    z-index: 100;
    cursor: none !important;
    /* right: auto; */
    /* left: 50%;   */
    /* margin-left: -2.5vw; */
    /* padding-top: 20vh; */
}

@media only screen and (max-width: 800px) {
    .switch {
      top: 13vh;
    }
}

@media only screen and (max-width: 500px) {
    .cursor {
      display: none;
    }
}