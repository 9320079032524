.hero {
    margin: 0 5vw;
    padding-top: 30vh;
}

.heading h1 {
    font-weight: 300;
    font-size: 2.3vw;
}

#light .heading h1 {
    color: black;
}

.text {
    margin-top: 7vh;
    margin-left: 5vw;
    max-width: 55vw;
}

.text h2 {
    font-size: 4vw;
    font-weight: 300;
    line-height: 8.5vh;
}

#light .text h2 {
    color: black;
}

.green {
    background-color: #DBFF00;
    color: black;
}

.red {
    background-color: #D34949;
}

.blue {
    background-color: #4695DF;
}

.orange {
    background-color: #C74D19;
}

.owl-theme {
    position: absolute;
    right: 5vw;
    bottom: 10vh;
    height: 40vh;
    background-color: gray;
    width: 18vw;
    height: 40vh;
}

.owl-stage-outer {
    height: 100%;
}

.owl-stage {
    height: 100%;
}

.owl-item {
    height: 100%;
}

.item {
    height: 100%;
}

.skills {
    width: 100%;
}

.skill-level {
    transform: rotate(-90deg);
    position: absolute;
    top: 10vh;
    left: -2vw;
    height: 6vw;
    width: 30vh;
}

.experience {
    transform: rotate(-90deg);
    position: absolute;
    top: 10vh;
    left: 5vw;
    height: 6vw;
    width: 30vh;
}

.skill-level .bar {
    height: 10px;
    width: 85%;
    background-color: white;
}

.experience .bar {
    height: 10px;
    width: 60%;
    background-color: white;
}

.special {
    height: 10px;
    background-color: white;
    width: 85%;
}

.item h1 {
    font-weight: 300;
    margin-bottom: 1vw;
    font-size: 1.5vw;
}

.item h2 {
    position: absolute;
    font-size: 2vw;
    bottom: 2vh;
    left: 2vw;
}

@media only screen and (max-width: 800px) {
    .hero {
        padding-top: 25vh;
    }

    .heading h1 {
        font-size: 7vw;
        text-align: center;
    }

    .text {
        margin-top: 7vh;
        margin-left: 5vw;
        max-width: 75vw;
    }

    .text h2 {
        font-size: 9vw;
        font-weight: 300;
        line-height: 6vh;
    }

    .owl-carousel {
        display: none !important;
    }
}