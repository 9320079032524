.details {
    padding-top: 20vh;
    height: 100vh;
}

.slider h1 {
    font-size: 8vw;
    font-weight: 300;
    letter-spacing: 2vw;
    color: rgba(255, 255, 255, 0.3);
    animation: marquee 20s linear infinite;
    transition: 0.3s;
    width: 110vw;
    z-index: 50;
}

#light .slider h1 {
    color: black;
}

.slider h1:hover {
    letter-spacing: 3vw;
}

@keyframes marquee {
    0% {
        transform: translate(100%, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

.details-text {
    position: absolute;
    right: 5vw;
    max-width: 35vw;
}

.details-text h2 {
    font-size: 4vw;
    margin-top: 5vh;
    font-weight: 300;
}

#light .details-text h2 {
    color: black;
}

.details-text h1 {
    font-weight: 300;
}

.details-text p {
    color: white;
    margin-top: 2vh;
    font-size: 1.9vw;
    font-weight: 300;
}

#light .details-text p {
    color: black;
}

.images {
    margin-top: 5vh;
    display: flex;
}

.images img {
    max-width: 3vw;
    margin-right: 1vw;
}

.photo {
    position: absolute;
    left: 15vw;
    top: 100vh;
    z-index: 0 !important;
}

.photo img {
    max-height: 90vh;
    z-index: 0 !important;
}

@media only screen and (max-width: 800px) {
    .details {
        padding-top: 10vh;
    }

    .photo {
        left: 0vw;
    }

    .photo img {
        max-height: 70vh;
    }

    .details-text {
        max-width: 70vw;
        background-color: rgba(255, 255, 255, 0.3);
        padding-left: 10vw;
        margin-top: 10vh;
    }

    .details-text h2 {
        font-size: 8vw;
    }

    .details-text p {
        font-size: 5vw;
    }

    .images img {
        max-width: 10vw;
        margin-right: 1vw;
    }
}

@media only screen and (max-width: 500px) {
    .slider {
        display: none;
    }
}